import { FORM_ERROR, SubmissionErrors } from 'final-form';

type Error = {
  message: string;
  response?: {
    data: {
      message: string;
      errors?: {
        [field: string]: string[];
      };
    };
  };
};

type Callback = (message: string | undefined) => void;

const formErrorTransform = (
  e: Error,
  callback?: Callback,
): SubmissionErrors => {
  let errors = {};
  if (e.response) {
    // eslint-disable-next-line no-unused-expressions
    callback?.(e.response.data.message);
    errors = {
      ...e.response.data.errors,
      [FORM_ERROR]: e.response.data.message,
    };
  } else {
    callback(e.message);
    errors = {
      [FORM_ERROR]: e.message,
    };
  }

  return errors;
};

export default formErrorTransform;
