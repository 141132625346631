import { TextField } from '@material-ui/core';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';

export const textFieldSubscription = {
  value: true,
  error: true,
  submitError: true,
  dirtySinceLastSubmit: true,
  touched: true,
};

const TextFieldAdapter: React.FC<
  FieldRenderProps<string, HTMLInputElement> & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputProps: { [k: string]: any };
  }
> = props => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    inputProps = {},
    ...rest
  } = props;
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const innerInputProps = {
    ...restInput,
    ...inputProps,
  };

  return (
    <TextField
      {...rest}
      name={name}
      helperText={
        showError ? meta.error || meta.submitError : rest.helperText ?? ''
      }
      error={showError}
      inputProps={innerInputProps}
      onChange={onChange}
      value={value}
    />
  );
};

export default TextFieldAdapter;
