import { projectId } from '@rtt-libs/constants';

export const BASE_API_URL = process.env.REACT_APP_API_URI;
export const FEEDBACK_API_URL = process.env.REACT_APP_FEEDBACK_API_URI;
export const RECAPTCHA_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

export const ENDPOINTS = {
  signin: 'signin?include=user',
  signup: 'signup?include=user',
  verifyPhoneNumber: 'verify/phone',
  verifyPhoneResend: 'verify/phone/resend',
  resetPass: 'reset-password',
  resetPassCheckPhone: 'reset-password/verify/phone',
  resetPassCheckCode: 'reset-password/verify/phone/check',
  resetPassResendCode: 'reset-password/verify/phone/resend',
  feedback: 'contact-form',
};

export const APP_ID = `${projectId}/land/`;

export const SMS_CODE_LENGTH = 4;

export const SHOW_HIDDEN_FEATURE =
  process.env.REACT_APP_SHOW_FEATURES === 'true';

/*
 * Feature flags
 * used as booleans
 *
 * `process.env.` values should be stringified numbers "0" or "1"
 */
export const FEATURE_SMS_SERVICE = process.env.FEATURE_SMS_SERVICE === '1';
