import { DEFAULT_LNG } from '@rtt-libs/constants';
import axios from 'axios';
import { BASE_API_URL } from '../environment';

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Accept-language': DEFAULT_LNG,
  },
});

export default api;
