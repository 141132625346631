/* eslint-disable @typescript-eslint/no-explicit-any */
type Validator<T> = (value: T | undefined) => string | undefined;
type ValidatorReturn = ReturnType<Validator<any>>;

export const composeValidators = (...validators: Validator<any>[]) => (
  value: string | undefined,
): ValidatorReturn =>
  validators.reduce(
    (error: ValidatorReturn, validator: Validator<any>) =>
      error || validator(value),
    undefined,
  );

export const required: Validator<any> = value =>
  value && String(value).trim() ? undefined : "Обов'язкове поле";

export const mustBeNumber: Validator<any> = value =>
  Number.isNaN(value) ? 'Must be a number' : undefined;

export const minValue = (min: number): Validator<number> => (
  value,
): ValidatorReturn =>
  Number.isNaN(value) || value >= min
    ? undefined
    : `Повинно бути більше за ${min}`;

export const minLength = (min: number): Validator<number> => (
  value,
): ValidatorReturn =>
  String(value).length >= min
    ? undefined
    : `Повинно бути більше ніж ${min} символів`;

export const maxLength = (max: number): Validator<number> => (
  value,
): ValidatorReturn =>
  String(value).length < max
    ? undefined
    : `Повинно бути менше ніж ${max} символів`;

export const isMobilePhone: Validator<string> = value =>
  /^\+\d{12,15}$/.test(value) ? undefined : 'Помилковий формат телефону';

export const isEmail: Validator<string> = value =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value,
  )
    ? undefined
    : 'Помилковий формат email-у';
